import { m } from 'framer-motion';
import Image from 'next/image';
import { useState } from 'react';

import { Icon } from '@/components/_atoms/Icon';
import { Typography } from '@/components/_atoms/Typography';

import helpBoxImg from './helpBox.jpg';

export const SelectCarHelp = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <button className="mb-6 flex items-center" onClick={() => setIsOpen((v) => !v)}>
        <Icon name="questionMark" color="grey-800" size={24} className="mr-2" />
        <Typography
          id="selectCarSizeModal.help"
          as="span"
          variant="body_m"
          color="grey-standard"
          className="text-left"
        />
        <m.span
          className="ml-1"
          animate={isOpen ? 'open' : 'closed'}
          variants={{
            open: {
              rotate: '180deg',
            },
            closed: {
              rotate: '0deg',
            },
          }}
        >
          <Icon name="chevronDown16" color="grey-600" size={16} />
        </m.span>
      </button>
      {isOpen && (
        <div className="flex justify-center overflow-hidden rounded-lg pb-6">
          <Image src={helpBoxImg} role="presentation" alt="Pomoc w wyborze rozmiaru" />
        </div>
      )}
    </>
  );
};
